<template>
  <div class="quality-start-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>质检中心</Breadcrumb-item>
        <Breadcrumb-item>抽查</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="card-container">
        <Card>
          <p slot="title">基本信息</p>
          <div class="card-box clearfix">
            <Row>
              <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
                <div class="card-avatar">
                  <div class="card-img">
                    <img :src="avater" alt="" width="80px" height="80px">
                  </div>
                  <p class="card-text">头像</p>
                </div>
              </Col>
              <Col :lg="{span: 22}" :md="{span: 20}">
                <div class="card-info">
                  <Form :label-width="85">
                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>身份证号：{{id_card}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>姓名：{{name}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>性别：{{sex | sex}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>民族：{{nation}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>生日：{{birthday}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>年龄：{{age}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>建档日期：{{create_time}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>服务员工：{{staff}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>联系电话：{{phone}}</p>
                      </Col>
                      <Col :lg="{span: 15}" :md="{span: 12}">
                        <p style="word-break: break-all">备注：{{remark}}</p>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="120">
          <Row>
            <Col span="24">
              <Form-item label="门店服务抽查：">
                <Radio-group v-model="doneForm.store_result">
                  <Radio label="1">属实</Radio>
                  <Radio label="0">不属实</Radio>
                </Radio-group>
                <Input v-model="doneForm.store_remark" type="textarea" :rows="4" placeholder="门店服务备注"></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="方案服务抽查：">
                <Radio-group v-model="doneForm.plan_result">
                  <Radio label="1">属实</Radio>
                  <Radio label="0">不属实</Radio>
                </Radio-group>
                <Input v-model="doneForm.plan_remark" type="textarea" :rows="4" placeholder="方案服务备注"></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="促进服务抽查：">
                <Radio-group v-model="doneForm.boost_result">
                  <Radio label="1">属实</Radio>
                  <Radio label="0">不属实</Radio>
                </Radio-group>
                <Input v-model="doneForm.boost_remark" type="textarea" :rows="4" placeholder="促进服务备注"></Input>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form-item label="医生服务抽查：">
                <Radio-group v-model="doneForm.doctor_result">
                  <Radio label="1">属实</Radio>
                  <Radio label="0">不属实</Radio>
                </Radio-group>
                <Input v-model="doneForm.doctor_remark" type="textarea" :rows="4" placeholder="医生服务备注"></Input>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="commom-panel-two">
        <div class="commom-panel-title">问题</div>
        <div class="commom-panel-main" v-for='(item, index) in doneProblem' :key="index">
          <Form class="search-form" :label-width="111">
            <Row>
              <Col span="24">
                <Form-item label="咨询种类：">
                  <Cascader :data="problem" style="width: 230px;" v-model="item.idd" change-on-select></Cascader>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="详细情况：">
                  <Input v-model="item.detail_info" type="textarea" :rows="4" placeholder="请输入详细情况"></Input>
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="是否上报解决：">
                  <Select v-model="item.should_report" placeholder="请选择" style="width: 230px;">
                    <Option :value="v.id" v-for="v in shouldList" :key="v.id">{{v.name}}</Option>
                  </Select>
                </Form-item>
              </Col>
            </Row>
            <Row>
            <Col span="24">
              <Form-item label="解决方案" v-if="item.should_report === '0'">
                <Input v-model="item.reslove" type="textarea" :rows="4" placeholder="请输入解决方案"></Input>
              </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="">
                  <Button @click="removePro(index)">删除</Button>
                </Form-item>
              </Col>
            </Row>
          </Form>
        </div>
        <div class="add-problem">
          <a href="javascript:;" class="add-btn" @click="newProblem">新建问题单</a>
        </div>
      </div>
      <div class="submit-box tac">
        <Button type="success" size="large" @click="submitDone()">提交</Button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import qualityService from '@/services/qualityService';
import { sex } from '@/filters/filters';
export default {
	components: {
		'v-title': vTitle,
	},
	filters: {
		sex,
	},
	data() {
		return {
			doneForm: {
				member_id: '',
				store_result: '',
				store_remark: '',
				plan_result: '',
				plan_remark: '',
				boost_result: '',
				boost_remark: '',
				doctor_result: '',
				doctor_remark: '',
				data_string: '',
			},
			doneProblem: [],
			problem: [],
			shouldList: [
				{
					id: '0',
					name: '已解决',
				},
				{
					id: '1',
					name: '上报',
				},
			],
			avater: '',
			id_card: '',
			name: '',
			sex: '',
			nation: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			phone: '',
			remark: '',
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		var param = { member_id: this.memberId };
		qualityService.getStart(param).then((data) => {
			this.avater = data.head_info.user_info.avater;
			this.id_card = data.head_info.user_info.id_card;
			this.name = data.head_info.user_info.name;
			this.sex = data.head_info.user_info.sex;
			this.remark = data.head_info.user_info.remark;
			this.nation = data.head_info.user_info.nation;
			this.birthday = data.head_info.user_info.birthday;
			this.age = data.head_info.user_info.age;
			this.create_time = data.head_info.user_info.create_time;
			this.staff = data.head_info.user_info.staff;
			this.phone = data.head_info.user_info.phone;
			this.doneForm.member_id = data.head_info.user_info.member_id;
			this.problem = data.problem_list;
		});
	},
	methods: {
		submitDone() {
			if (this.validateFormData()) {
				for (var i = 0; i < this.doneProblem.length; i++) {
					this.doneProblem[i].member_id = this.memberId;
					this.doneProblem[i].level1_id = this.doneProblem[i].idd[0]
						? this.doneProblem[i].idd[0]
						: '';
					this.doneProblem[i].level2_id = this.doneProblem[i].idd[1]
						? this.doneProblem[i].idd[1]
						: '';
					delete this.doneProblem[i].idd;
					if (this.doneProblem[i].should_report === '1') {
						this.doneProblem[i].reslove = '';
					}
				}
				this.doneForm.data_string = JSON.stringify(this.doneProblem);
				qualityService.qualityDone(this.doneForm).then(() => {
					this.$router.push({ path: '/base/quality' });
				});
			}
		},
		validateFormData() {
			for (var i = 0; i < this.doneProblem.length; i++) {
				if (!this.doneProblem[i].idd[0]) {
					this.$Message.warning('请选择各问题单的咨询种类');
					return false;
				}
				if (this.doneProblem[i].detail_info.length === 0) {
					this.$Message.warning('请输入问题详细情况');
					return false;
				}
				if (this.doneProblem[i].should_report === '0' && !this.doneProblem[i].reslove) {
					this.$Message.warning('请填写解决方案');
					return false;
				}
			}
			return true;
		},
		newProblem() {
			this.doneProblem.push({
				source: '质检',
				member_id: '',
				level1_id: '',
				level2_id: '',
				detail_info: '',
				should_report: '0',
				reslove: '',
				idd: [],
			});
		},
		removePro(index) {
			this.doneProblem.splice(index, 1);
		},
	},
};
</script>

<style lang="css" scoped>
.quality-start-container .card-container .ivu-card-head > p {
  color: #fff;
}
.quality-start-container .card-container .card-box {
  position: relative;
}
.quality-start-container .card-container .ivu-col {
  margin-bottom: 20px;
}
.quality-start-container .card-container .card-avatar {
  padding: 30px 0;
}
.quality-start-container .card-container .card-img img {
  border-radius: 100%;
}
.quality-start-container .card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.quality-start-container .card-container .card-info {
  padding-top: 30px;
}
.quality-start-container .card-container .ivu-form-item {
  margin-bottom: 0;
}
.quality-start-container .clicka {
  color: #ff8400;
  text-decoration: underline;
}
.quality-start-container .submit-box {
  margin-top: 30px;
}
.quality-start-container .ivu-btn-success {
  padding: 11px 48px;
  margin: 0 26px;
}
.quality-start-container .add-problem {
  padding: 10px 30px;
}
.quality-start-container .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.quality-start-container .ivu-radio-wrapper {
  margin-right: 30px;
}
</style>
